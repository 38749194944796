import { useEffect } from 'react';
import { useRouter } from 'next/router';
import Homepage from '@cont/Homepage/Homepage';
import SEO from '@seo/index';
import homepage from '@seo/homepage/homepage.json';
import {
  fetchPopularGoalsV2,
  fetchGoalDisplayNames,
  fetchLegendsBanner,
  fetchOneCarouselFeed,
  fetchRootPageData
} from '@api/fetchers/home';
import { readCookie } from '@utils/cookies';
// SWR Hooks
import { SWRConfig } from 'swr';
import useHomeInfo from '@api/hooks/home/useHomeInfo';
import FreeLayout from '@layout/FreeLayout/FreeLayout';
import AarambhBanner from '@cont/NewPreSubscribed/AarambhBanner';
import { fetchAarambhEventDetails } from '@api/fetchers/goal';
import styled from '@emotion/styled';
import { Spacings, mediaQuery } from '@styles/index';

const PageContainer = styled.div`
  margin-top: ${Spacings.SPACING_18B};

  ${mediaQuery.uptoMobile} {
    margin-top: ${Spacings.SPACING_12B};
  }
`;

const Index = ({ fallbackData }) => {
  const router = useRouter();
  const url = 'https://unacademy.com';
  const { keys } = useHomeInfo(fallbackData);
  const preSelectedGoalUid = readCookie('selectedGoalUid');

  useEffect(() => {
    if (preSelectedGoalUid) router.push(`/goal/-/${preSelectedGoalUid}`);
  }, [router, preSelectedGoalUid]);

  return (
    <SWRConfig
      value={{
        revalidateOnMount: true,
        dedupingInterval: 60 * 1000,
        fallback: {
          [keys.popularGoalsGroupsKey]: fallbackData.popularGoalsData,
          [keys.legendBannerKey]: fallbackData.legendBannerData,
          [keys.carouselDataKey]: fallbackData.carouselData,
          [keys.rootPageDataKey]: fallbackData.rootPageData,
          [keys.goalDisplayNameskey]: fallbackData.goalDisplayNamesData
        }
      }}
    >
      <SEO schema={homepage} url={url} openGraph={{ url }} />
      <PageContainer>
        <AarambhBanner details={fallbackData.aarambhEventDetails} />
        <Homepage />
      </PageContainer>
    </SWRConfig>
  );
};

Index.pageInfo = {
  name: 'Unacademy Home',
  layoutOptions: {
    hideSearch: true,
    hideGoalSelector: true,
    disableDarkMode: true,
    hideIntercom: true,
    hideFreshbot: true
  },
  isCMSMeta: true,
  isMWebSupported: true
};

export const getStaticProps = async () => {
  const [
    popularGoalsData,
    legendBannerData,
    rootPageData,
    goalDisplayNamesData,
    carouselData,
    aarambhEventDetails
  ] = await Promise.all([
    fetchPopularGoalsV2(),
    fetchLegendsBanner(),
    fetchRootPageData(),
    fetchGoalDisplayNames(),
    fetchOneCarouselFeed(),
    fetchAarambhEventDetails()
  ]);

  return {
    props: {
      fallbackData: {
        popularGoalsData,
        legendBannerData,
        carouselData,
        rootPageData,
        goalDisplayNamesData,
        aarambhEventDetails
      }
    },
    revalidate: 5
  };
};

export default Index;
Index.Layout = FreeLayout;
