import Image from '@base/FutureImage';
import H4 from '@base/H4/H4';
import styled from '@emotion/styled';
import { Fonts, Spacings, mediaQuery } from '@styles/index';
import React from 'react';

const Container = styled.div`
  position: relative;
  height: 546px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${Spacings.SPACING_10B};
  ${mediaQuery.uptoMobile} {
    height: 373px;
  }
`;

const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  gap: ${Spacings.SPACING_6B};

  ${mediaQuery.uptoMobile} {
    gap: ${Spacings.SPACING_4B};
  }
`;
const SubTitle = styled(H4)`
  text-align: center;
  line-height: ${Spacings.SPACING_5B} !important;

  ${mediaQuery.uptoMobile} {
    width: 292px;
    font-size: ${Fonts.SMALL};
  }
`;
const BgContainer = styled.div`
  width: 100%;
  position: absolute;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
  }
  ${mediaQuery.uptoMobile} {
    height: 373px;
  }
`;

const IconWrapper = styled.div`
  width: 297px;

  ${mediaQuery.uptoMobile} {
    width: 178px;
  }

  img {
    width: 100%;
    height: 100%;
  }
`;

const VideoWrapper = styled.div`
  /* margin-top: 16px; */
  width: 512px;
  height: 286px;
  z-index: 1;
  background-color: transparent;
  border: ${Spacings.SPACING_1B} solid var(--color-i-white);
  border-radius: ${Spacings.SPACING_5B};
  overflow: hidden;
  padding: ${Spacings.SPACING_2};

  ${mediaQuery.uptoMobile} {
    width: 320px;
    height: 179px;
    border-radius: ${Spacings.SPACING_3B};
    border: ${Spacings.SPACING_2} solid var(--color-i-white);
  }
`;

const Iframe = styled.iframe`
  height: 100%;
  width: 100%;
  border-radius: ${Spacings.SPACING_4B};
  border: none;
  ${mediaQuery.uptoMobile} {
    border-radius: ${Spacings.SPACING_2B};
  }
`;

const AarambhBanner = ({ details }) => {
  const { subTitle, imageUrl, videoUrl, titleLogo } = details;
  if (!videoUrl || videoUrl.indexOf('embed') < 0) return null;
  return (
    <Container>
      <DetailContainer>
        {titleLogo && (
          <IconWrapper>
            <Image src={titleLogo} height="40" width="297" alt="" />
          </IconWrapper>
        )}
        <SubTitle color="var(--color-i-white)">{subTitle}</SubTitle>
      </DetailContainer>
      <VideoWrapper>
        <Iframe
          src={videoUrl}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        />
      </VideoWrapper>

      <BgContainer>
        <Image src={imageUrl} height="546" width="1442" alt="" />
      </BgContainer>
    </Container>
  );
};

export default AarambhBanner;
